// Models
import { AppEnvironmentConfig } from 'src/app/shared/models/environment-config.model';

/**
 * App environment config for development
 */
export const environment: AppEnvironmentConfig = {
  PRODUCT_NAME: 'ADMIN',
  API: `https://exp-api-gateway-webmdignite-app-dev.mercury.ike.tools/api`,
  AUTH0: {
    domain: 'auth0.exp-dev.mercuryhealthcare.com',
    tenant: 'healthgrades-dev',
    clientId: 'NHB6ytgB11vjDD1rxBjB7r2hVPfyNdJJ',
  },
  SESSION: {
    refreshSessionInterval: 600, // Refresh API sent every 10 minutes
    logoutNotifyExpiryTime: 30, // 30 secs; Notification waits this time for a user action. If no-action, the user is logged off
    maxIdleWaitTime: 900, // (In Seconds) 15 minutes; When there is no events from a user, a notification is shown
  },
  CLIENTLIST: 1000,
  LOCAL_STORAGE_ENCRYPTION: 'mM0rKjdQwzWIXCw', // RANDOM TEXT USED TO ENCRYPT LOCAL STORAGE VALUES
};
